export const setObjectKeyValue = (object, key, value) => {
  if (!object) return;
  Object.keys(object).forEach((element) => {
    if (typeof object[element] === 'object') {
      setObjectKeyValue(object[element], key, value);
    } else if (element === key) {
      object[element] = value;
    }
  });
};

export const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    this.$snotify.success('Pomyślnie skopiowano do schowka');
  } catch (err) {
    this.$snotify.error(err || 'Nie udało się skopiwać tekstu');
    console.warn(err);
  }
};
