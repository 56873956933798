import Vue from 'vue';
import configurationApiService from '@/services/spedition/configuration-api';
import { setObjectKeyValue } from '@/mixins/helper';
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash-es';

export default {
  data: () => ({
    loading: false,
    isNew: false,
  }),

  computed: {
    ...mapGetters('spedition', ['getConfigurationSet', 'getOrganization']),
  },

  created() {
    this.organizationId = this.getOrganization().id;
    this.isNew = !this.$route.params.confSetId;
  },

  mounted() {
    if (this.isNew) {
      this.setNewConfiguration();
      this.getConfigurationSet().courier = this.speditor;
    }
  },

  beforeRouteLeave(to, from, next) {
    this.clearValues();
    next();
  },

  methods: {
    ...mapActions('spedition', ['setConfigSet', 'clearValues']),
    setObjectKeyValue,

    async save() {
      this.loading = true;
      this.setObjectKeyValues();
      let confSetId = this.getConfigurationSet().id;
      try {
        if (this.isNew) {
          const { data } = await configurationApiService.createConfigurationSet(
            this.speditor,
            this.getConfigurationSet(),
            this.organizationId,
          );
          confSetId = data.id;
        } else {
          await configurationApiService.updateConfigurationSet(
            this.speditor,
            this.getConfigurationSet(),
            this.organizationId,
          );
        }
        const confSet = await configurationApiService.getConfigurationSet(
          this.speditor,
          confSetId,
          confSetId,
          this.organizationId,
        );
        await this.setConfigSet({ response: confSet.data });
        this.isNew = false;
        this.$snotify.success('Pomyślnie zapisano');
      } catch (err) {
        this.$snotify.error('Błąd');
      } finally {
        this.loading = false;
      }
    },

    changeComponent(component) {
      this.currentComponent = component;
    },

    setObjectKeyValues() {
      // tymczasowe (lub trwałe) obejście problemu z referencjami w c#
      // defaultvalues to getter, który zwraca [nazwa_kuriera]Defaultvalues
      // z tego powodu bezpośrednia edycja [nazwa_kuriera]Defaultvalues nie jest możliwa
      // do zrobienia w przyszłości: przejście na defaultValues we wszystkich integracjach
      // lub poprawa problemu po stronie BE
      const defaultParams = Object.keys(this.getConfigurationSet())
        .filter((item) => item.toLowerCase().includes('defaultvalues'))
        .sort((a, b) => a.length - b.length);

      if (!!defaultParams && defaultParams.length === 2) {
        const courierDefaultValues = this.getConfigurationSet()[defaultParams[1]];
        Vue.set(this.getConfigurationSet(), defaultParams[0], courierDefaultValues);
      }
      this.setObjectKeyValue(this.getConfigurationSet(), 'tenantId', this.organizationId);

      // Dla nowego zestawu ustawiam wszędzie flagę clientEntityState na 3 (Edition)
      // additionalServices i fieldsMappings to kolekcje, które wymagają dodatkowej logiki.
      // Z tego powodu są przepisane.
      // Np. Jeśli usuniemy istniejący element w additionalServices to musimy zmienić wartość flagi clientEntityState na 2 dla tego elementu
      if (!this.isNew) {
        const additionalServices = cloneDeep(this.getConfigurationSet().additionalServices);
        const fieldsMappings = cloneDeep(this.getConfigurationSet().fieldsMappings);
        this.setObjectKeyValue(this.getConfigurationSet(), 'clientEntityState', 3);
        Vue.set(this.getConfigurationSet(), 'additionalServices', additionalServices);
        Vue.set(this.getConfigurationSet(), 'fieldsMappings', fieldsMappings);
      }
    },
  },
};
