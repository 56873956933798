<template>
  <v-card elevation="0">
    <div>
      <v-img
        :src="logo"
        contain
        height="128px"
        width="241px"
        class="center"
      />
    </div>
    <v-col>
      <v-divider />
    </v-col>
    <v-responsive />
    <v-list
      dense
      nav
    >
      <div
        v-for="component in components"
        :key="component.code"
        :class="{
          'navigation-item-selected': component.code === currentComponent,
          'navigation-item': isSideBarActive,
        }"
        tabindex="0"
        @click="isSideBarActive && $emit('@changeComponent', component.code)"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <span :class="{ 'text--priority_gray': !isSideBarActive }">{{
                $t(component.name)
              }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </v-card>
</template>

<script lang="ts">
import { speditors } from '@/constants/Speditors';

export default {
  name: 'ConfigurationSetSideNavigation',
  props: {
    components: { type: Array, default: () => [] },
    speditor: { type: String, default: undefined },
    currentComponent: { type: String, default: 'ServiceAuthorization' },
    isSideBarActive: { type: Boolean, default: true },
  },

  data: () => ({
    speditors,
  }),

  computed: {
    logo() {
      const images = require.context('@/assets/images/logo/speditors/', false, /\.png$/);
      if (this.speditor === 'CustomShipment') {
        return images('./CustomShipment.png');
      }
      const spedLogo = this.speditors.find((x) => x.code === this.speditor).logo;
      return images(`./${spedLogo}`);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/utilities/variables';

.navigation-item {
  border-left: 3px solid $white;

  &-selected {
    border-left: 3px solid $primary-lighten1;
    background-color: $primary-lighten4;
  }

  &:hover {
    border-left: 3px solid $primary-lighten4;
    background-color: $primary-lighten4;
  }
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
