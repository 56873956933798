export const Content = {
  clientEntityState: 4,
  tenantId: '',
  id: '',
  isCustomDeclarable: false,
  declaredValue: 0,
  description: '',
  usFilingTypeValue: '',
  incoterm: '',
  unitOfMeasurement: '',
};
