export const dhlAdditionalServices = [
  {
    id: 1,
    serviceId: 'COD',
    name: 'Pobranie',
    description: 'Zwrot pobrania (dodatkowo płatne)',
    parameters: true,
    remark: '',
  },
  {
    id: 2,
    serviceId: 'INSURANCE',
    name: 'Ubezpieczenie',
    description: 'Ubezpieczenie przesyłki (dodatkowo płatne)',
    parameters: true,
    remark: '',
  },
];
