<template>
  <vee-form
    ref="form"
    v-slot="{ invalid }"
  >
    <v-form v-model="isFormValid">
      <v-container>
        <v-row>
          <v-col
            offset-xl="2"
            xl="7"
            cols="11"
            class="d-flex justify-end"
          >
            <configuration-set-actions
              :invalid="(useVForm ? !isFormValid : invalid) || loading"
              @@save="$emit('@save')"
            />
          </v-col>
        </v-row>
        <v-row justify="space-around">
          <v-col
            offset="1"
            cols="3"
            offset-xl="2"
            xl="2"
          >
            <configuration-set-side-navigation
              :components="components"
              :speditor="speditor"
              :current-component="currentComponent"
              :is-side-bar-active="isSideBarActive"
              :loading="loading"
              @@changeComponent="changeComponent"
            />
          </v-col>
          <v-col
            offset-xl="1"
            xl="5"
            cols="7"
            class="ml-0"
          >
            <slot name="content" />
          </v-col>
          <v-responsive />
        </v-row>
      </v-container>
    </v-form>
  </vee-form>
</template>

<script lang="ts">
import ConfigurationSetSideNavigation from '@/components/shared/ConfigurationSetSideNavigation.vue';
import ConfigurationSetActions from '@/components/shared/ConfigurationSetActions.vue';

export default {
  components: {
    ConfigurationSetActions,
    ConfigurationSetSideNavigation,
  },

  props: {
    components: { type: Array, default: () => [] },
    speditor: { type: String, default: undefined },
    currentComponent: { type: String, default: 'ServiceAuthorization' },
    isSideBarActive: { type: Boolean, default: true },
    loading: { type: Boolean, default: false },
    useVForm: Boolean,
  },

  data: () => ({
    isFormValid: false,
  }),

  methods: {
    changeComponent(component) {
      this.$emit('@changeComponent', component);
    },
  },
};
</script>
